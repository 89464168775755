import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post("/api/auth/login", { email, password });
  }

  signup(email: string, password: string): Observable<any> {
    return this.http.post("/api/auth/signup", { email, password });
  }

  sendOtp(email: string): Observable<any> {
    return this.http.post("/api/auth/send-otp", { email });
  }

  sendPasswordResetOtp(email: string) {
    return this.http.post("/api/auth/password-reset-otp", { email });
  }

  verifyOtp(email: string, otp: string) {
    return this.http.post("/api/auth/verify-otp", { email, otp });
  }

  resetPassword(password: string, confirmPassword: string, token: string) {
    return this.http.post("/api/auth/reset-password", { password, confirmPassword, token });
  }

  gooleSignIn(): Observable<any> {
    return this.http.get("/api/auth/google");
  }

  sendResetPasswordEmail(email: string) {
    return this.http.post("/api/auth/send-reset-email", { email });
  }

  logout() {
    return this.http.get("/api/auth/logout");
  }
}
